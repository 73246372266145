// packages
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material'
// styles
import './styles/App.scss'

import Navbar from './utils/Navbar/Navbar'

// route pages
import Home from './routes/home/Home'
import LoginOrRegister from './routes/loginOrRegister/LoginOrRegister'
import Verify from './routes/verify/Verify'
import NotFound from './routes/notFound/NotFound'
import NewSet from './routes/newSet/NewSet'
import Profile from './routes/profile/Profile'
import OpenSet from './routes/openSet/openSet'
import BrowseSet from './routes/browseSet/BrowseSet'
import WriteSet from './routes/writeSet/writeSet'
import Roadmap from './routes/roadmap/Roadmap'
import WriteTest from './routes/writeTest/WriteTest'
import Download from './routes/download/Download'
import JoinMatch from './routes/joinMatch/JoinMatch' //nie wiem czemu wyrzuca błąd
import CreateMatch from './routes/createMatch/CreateMatch'
import Lobby from './routes/lobby/Lobby'
import Match from './routes/match/Match'

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />,
        errorElement: <NotFound />,
    },
    {
        path: '/register/',
        element: <LoginOrRegister starting={'register'} />,
    },
    {
        path: '/login/',
        element: <LoginOrRegister starting={'login'} />,
    },
    {
        path: '/verify/',
        element: <Verify />,
    },
    {
        path: '/newSet/',
        element: <NewSet />,
    },
    {
        path: '/profile/',
        element: <Profile />,
    },
    {
        path: '/openSet/:setID',
        element: <OpenSet />,
    },
    {
        path: '/browseSet/:setID',
        element: <BrowseSet />,
    },
    {
        path: '/write/:setID',
        element: <WriteSet />,
    },
    {
        path: '/profile/:userID',
        element: <Profile />,
    },
    {
        path: '/roadmap',
        element: <Roadmap />,
    },
    {
        path: '/test/:setID',
        element: <WriteTest />,
    },
    {
        path: '/download',
        element: <Download />,
    },
    {
        path: '/join',
        element: <JoinMatch />,
    },
    {
        path: '/createMatch/:setID',
        element: <CreateMatch />,
    },
    {
        path: '/lobby/:matchID',
        element: <Lobby />,
    },
    {
        path: '/match/:matchID/:setID',
        element: <Match />,
    },
])

const theme = createTheme({
    palette: {
        mode: 'dark',
        font: {
            color: '#ffffff',
        },
        primary: {
            main: '#6e19ab',
        },
        secondary: {
            main: '#c50404',
        },
        background: {
            // default: '#02161f',
            paper: '#042C3D',
            default: '#00151f',
        },
        error: {
            main: '#ff0000',
        },
        facebook: {
            main: '#4267B2',
        },
        google: {
            main: '#DB4437',
        },
    },
})

const App = () => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <RouterProvider router={router} />
            </ThemeProvider>
        </>
    )
}

export default App
