import { useTheme } from '@mui/material'
import Navbar from '../../utils/Navbar/Navbar'
import './roadmap.scss'

const Roadmap = () => {
    const theme = useTheme()

    return (
        <>
            <Navbar />
            <div className='roadmapMain' style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
                <header>Aktualny status aplikacji Fishki</header>
                <section>
                    <header>
                        Aplikacja Fishki na ten moment <span style={{ color: theme.palette.primary.light }}> nie jest skończona. </span>{' '}
                    </header>
                    Większość funkcji działa bez problemów <br></br>
                    Kilka funkcji nie jest testowana i może powodować <span style={{ color: theme.palette.error.main }}> błędy</span> <br></br>
                    Jest też kilka <span style={{ color: theme.palette.warning.main }}> brakujących </span> elementów <br></br>
                    Wszystkie informacje dostępne poniżej
                </section>
                <section className='warnings'>
                    <header>
                        Funkcje które mogą powodować <span style={{ color: theme.palette.warning.main }}>problemy:</span>{' '}
                    </header>
                    <ul>
                        <li>Wygląd wersji mobilnej (na niektórych stronach)</li>
                        <li>Dodawanie zestawów fiszek</li>
                        <li>Logowanie przez fb i google</li>
                        <li>Generowanie i wykonywanie testów</li>
                        <li>Podstrona profilu użytkownika</li>
                        <li>1v1 online</li>
                    </ul>
                </section>
                <section className='errors'>
                    <header>
                        Funkcje których <span style={{ color: theme.palette.error.main }}>brakuje</span>{' '}
                    </header>
                    <ul>
                        <li>Udostępnianie testów</li>
                    </ul>
                </section>
                <footer style={{ color: theme.palette.success.main }}>Wszystkie problemy naprawimy tak szybko jak będziemy mogli :))</footer>
            </div>
        </>
    )
}

export default Roadmap
